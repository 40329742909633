import { createSlice, createAsyncThunk, AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { api } from "../../../Utils";
import { RootState } from "../../Store";

// export const getCricketData = createAsyncThunk('getCricketData', async ({ status, page, limit }: { status: any, page: number, limit: number }) => {
//     try {
//       const res = await api(`/sport/cricket?status=${status}&page=${page}&limit=${limit}`, "get", false, false, true);
//       console.log("res--------", res);
//       if(!res.data){
//         return res?.response?.data?.message
//       }else{
//         return res.data;
//       }
//     } catch (err) {
//       console.log("err", err);
//       throw err;
//     }
// });

export const getCricketData = createAsyncThunk(
  'getCricketData',
  async ({ status, page, limit, fetchAll }: { status: any, page: any, limit: any, fetchAll?: boolean }) => {
    try {
      let url = `/sport/cricket?status=${status}`;
      if (!fetchAll) {
        url += `&page=${page}&limit=${limit}`;
      }
      const res = await api(url, "get", false, false, true);
      console.log("res--------", res);
      if (!res.data) {
        return res?.response?.data?.message;
      } else {
        return res.data;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
);

export const getLiveMatchesData = createAsyncThunk('getLiveMatchesData', async (_, { rejectWithValue }) => {
  try {
      const res = await api(`/sport/live-scoring-demo`, "get", false, false, true);
      console.log("res--------", res);
      return res?.data?.data;
  } catch (err: any) {
      console.log("err", err);
      return rejectWithValue(err.message);
  }
});

// Sport slice
const SportSlice = createSlice({
  name: "sport",
  initialState: {
    cricket: {
        isLoading: false,
        data: null as any | null,
        isError: false,
        errorMessage: "",
        pagination: null
    },
    live_page_demo: {
      isLoading: false,
      data: null as any,
      isError: false,
      errorMessage: "",
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    // getCricketData
    builder.addCase(getCricketData.pending, (state, action) => {
      state.cricket.isLoading = true;
    });
    builder.addCase(getCricketData.fulfilled, (state, action) => {
        state.cricket.isLoading = false;
        console.log("action...pa", action.payload);
        state.cricket.data = action.payload?.data;
        state.cricket.pagination = action.payload?.pagination;
    });
    builder.addCase(getCricketData.rejected, (state, action) => {
        console.log("error in getCricketData", action.payload);
        state.cricket.isLoading = false;
        state.cricket.isError = true;
    });

    // getLiveMatchesData
    builder.addCase(getLiveMatchesData.pending, (state, action) => {
      state.live_page_demo.isLoading = true;
    });
    builder.addCase(getLiveMatchesData.fulfilled, (state, action) => {
        state.live_page_demo.isLoading = false;
        console.log("getLiveMatchesData action...pa", action.payload);
        state.live_page_demo.data = action.payload;
    });
    builder.addCase(getLiveMatchesData.rejected, (state, action) => {
        console.log("error in getLiveMatchesData", action.payload);
        state.live_page_demo.isLoading = false;
        state.live_page_demo.isError = true;
    });
  }
});

export const {  } = SportSlice.actions;
export const sportReducer = SportSlice.reducer;
