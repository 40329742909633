import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H3, H4, Image, P } from "../../AbstractElements";
import { dynamicImage } from "../../Service";
import { CreateAccount, DoNotAccount, EmailAddress, ForgotPassword, Href, Password, RememberPassword, SignIn, SignInAccount, SignInWith } from "../../utils/Constant";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SocialApp from "./SocialApp";
import { login } from "../../ReduxToolkit/Reducers/Change/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../ReduxToolkit/Store";
import { Loading } from "../../Utils";
import BottomRightToast from "../BonusUi/Toast/LiveToast/BottomRightToast/BottomRightToast";

const Login = () => {
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [txt, setTxt] = useState("");
    const userData = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
  
    const SimpleLoginHandle = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = {
        email: email,
        password: password
      }
      try {
        if(email === "" || password === ""){
          setTxt("Both fields are required");
          setShowToast(true);
        }else{
          const res = await dispatch(login(data)).unwrap();
          console.log("res---- login", res);
          const userJsonString = JSON.stringify({ user: res.data, token: res.token });
          localStorage.setItem("login", JSON.stringify(true));
          localStorage.setItem("login-user", userJsonString);
          navigate(`${process.env.PUBLIC_URL}/dashboard/home`);
          // Handle successful signup, e.g., redirect to another page or show a success message
        }
      } catch (error) {
        console.error("Signup error:", error);
        setTxt("Invalid email or password");
        setShowToast(true);
      }
    };

    useEffect(() => {
      console.log("email, password", email, password);
    }, [email, password]);
  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div>
                <Link className="logo text-center" to={Href}>
                  <Image className="img-fluid for-light" src={dynamicImage("logo/logo.png")} alt="looginpage" />
                  <Image className="img-fluid for-dark" src={dynamicImage("logo/logo_dark.png")} alt="looginpage" />
                </Link>
              </div>
              <div className="login-main">
                <Form className="theme-form" onSubmit={(e) => SimpleLoginHandle(e)} >
                  <H3>{SignInAccount}</H3>
                  <P>{"Enter your email & password to login"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input autoComplete="off" type="email" required placeholder="Your email" value={email} name="email" onChange={(event) => setEmail(event.target.value)}  />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <div className="form-input position-relative">
                      <Input autoComplete="off" type={show ? "text" : "password"} placeholder="*********"  onChange={(event) => setPassword(event.target.value)} value={password} name="password"/>
                      <div className="show-hide" onClick={() => setShow(!show)}>
                        <span className="show"> </span>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-0 form-sub-title">
                    <div className="checkbox p-0">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" htmlFor="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>
                    <Link to={`${process.env.PUBLIC_URL}/authentication/forget_password`}  >
                      {ForgotPassword}
                    </Link>
                    <div className="text-end mt-3">
                      <Btn color="primary" block  className="w-100" >
                      {userData.isLoading?<Loading/>:"SignIn"}
                      </Btn>
                    </div>
                  </FormGroup>
                  {/* <H4 className="text-muted mt-4 or">{SignInWith}</H4>
                  <SocialApp /> */}
                  <P className="mt-4 mb-0 text-center">
                    {DoNotAccount}
                    <Link className="ms-2" to={`${process.env.PUBLIC_URL}/authentication/register_simple`} >
                      {CreateAccount}
                    </Link>
                  </P>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {showToast && <BottomRightToast txt={txt} open={showToast} setOpenToast={setShowToast} />}
    </Container>
    // <section className="section-box box-content-login">
    //                 <div className="container">
    //                     <div className="row align-items-center">
    //                         <div className="col-lg-6">
    //                             <div className="box-form-register">
    //                                 <h3 className="title-register">Welcome Back</h3>
    //                                 <p className="text-md neutral-700">Today is a new day. It's your day. You shape it. Sign in to start managing your projects.</p>
    //                                 <form className="form-register" action="#">
    //                                     <div className="form-group">
    //                                         <label>Your Email<span className="brand-1">*</span></label>
    //                                         <input className="form-control" type="text" placeholder="email@website.com" />
    //                                     </div>
    //                                     <div className="form-group">
    //                                         <label>Password *</label>
    //                                         <input className="form-control" type="password" />
    //                                     </div>
    //                                     <div className="form-group-cb">
    //                                         <div className="d-flex justify-content-between align-items-center">
    //                                             <label className="text-md">
    //                                                 <input className="cb-agree" type="checkbox" />Remember me
    //                                             </label><Link to="" className="text-md neutral-500">Forgot password?</Link>
    //                                         </div>
    //                                     </div>
    //                                     <div className="form-group">
    //                                         <button className="btn btn-black btn-rounded">Login
    //                                             <svg xmlns="http://www.w3.org/2000/svg" width={23} height={8} viewBox="0 0 23 8" fill="none">
    //                                                 <path d="M22.5 4.00032L18.9791 0.479492V3.3074H0.5V4.69333H18.9791V7.52129L22.5 4.00032Z" fill="true" />
    //                                             </svg>
    //                                         </button>
    //                                     </div>
    //                                     <div className="other-login mt-45">
    //                                         <p className="text-md neutral-500">Don’t have an account?<Link to="" className="brand-1-1">Sign Up</Link></p>
    //                                     </div>
    //                                 </form>
    //                             </div>
    //                         </div>
    //                         <div className="col-lg-6">
    //                             <div className="box-image-banner-login"><img src="/assets/imgs/page/login/banner.png" alt="Nivia" />
    //                                 <ul className="list-logos-login">
    //                                     <li>
    //                                         <div className="item-logo"><img src="/assets/imgs/page/homepage3/logo2.png" alt="Nivia" /></div>
    //                                     </li>
    //                                     <li>
    //                                         <div className="item-logo"><img src="/assets/imgs/page/homepage3/logo3.png" alt="Nivia" /></div>
    //                                     </li>
    //                                     <li>
    //                                         <div className="item-logo"><img src="/assets/imgs/page/homepage3/logo4.png" alt="Nivia" /></div>
    //                                     </li>
    //                                     <li>
    //                                         <div className="item-logo"><img src="/assets/imgs/page/homepage3/logo5.png" alt="Nivia" /></div>
    //                                     </li>
    //                                     <li>
    //                                         <div className="item-logo"><img src="/assets/imgs/page/homepage3/logo6.png" alt="Nivia" /></div>
    //                                     </li>
    //                                     <li>
    //                                         <div className="item-logo"><img src="/assets/imgs/page/homepage3/logo7.png" alt="Nivia" /></div>
    //                                     </li>
    //                                     <li>
    //                                         <div className="item-logo"><img src="/assets/imgs/page/homepage3/logo8.png" alt="Nivia" /></div>
    //                                     </li>
    //                                     <li>
    //                                         <div className="item-logo"><img src="/assets/imgs/page/homepage3/logo9.png" alt="Nivia" /></div>
    //                                     </li>
    //                                     <li>
    //                                         <div className="item-logo"><img src="/assets/imgs/page/homepage3/logo1.png" alt="Nivia" /></div>
    //                                     </li>
    //                                 </ul>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </section>
  );
};

export default Login;
