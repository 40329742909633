import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  CardHeader,
  Table,
  Badge,
  Button,
  ListGroup,
  ListGroupItem,
  Spinner,
} from "reactstrap";
import {
  MapPin,
  Clock,
  Award,
  TrendingUp,
  MessageCircle,
  FileText,
  Users,
  Video,
  Info,
  ChevronDown,
  ChevronUp,
  Rss,
} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../ReduxToolkit/Store";
import { getLiveMatchesData } from "../../ReduxToolkit/Reducers/Change/SportSlice";

const LiveScoringDemo = () => {
  const dispatch = useDispatch<AppDispatch>();
  const liveMatchesData = useSelector((state: RootState) => state.sport.live_page_demo);

  const [selectedMatchId, setSelectedMatchId] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("summary");
  const [showPlaying11, setShowPlaying11] = useState<boolean>(false);
  const [showVenueDetails, setShowVenueDetails] = useState<boolean>(false);

  const fetchLiveMatches = useCallback(async () => {
    try {
      await dispatch(getLiveMatchesData()).unwrap();
    } catch (error) {
      console.error("Error fetching live matches:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchLiveMatches();
  }, [fetchLiveMatches]);

  useEffect(() => {
    if (liveMatchesData?.data?.[0]?.live_matches?.length > 0) {
      setSelectedMatchId(liveMatchesData.data[0].live_matches[0].id);
    }
  }, [liveMatchesData]);

  const selectedMatch = liveMatchesData?.data?.[1]?.match_data?.find(
    (item: any) => item.match__id === selectedMatchId
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case "summary":
        return (
          <div>
            <h4 className="mb-4">Match Summary</h4>
            {selectedMatch && (
              <Row>
                <Col md={6}>
                  <Card className="mb-4">
                    <CardHeader className="bg-light">
                      <h5 className="mb-0">
                        <TrendingUp className="me-2" />
                        Top Batter
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <h5>{selectedMatch.live_match?.top_batter?.name || "N/A"}</h5>
                      <p className="mb-0">
                        {selectedMatch.live_match?.top_batter?.runs || 0} (
                        {selectedMatch.live_match?.top_batter?.balls_faced || 0})
                      </p>
                      <p>
                        4s: {selectedMatch.live_match?.top_batter?.fours || 0} • 6s:{" "}
                        {selectedMatch.live_match?.top_batter?.sixes || 0}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="mb-4">
                    <CardHeader className="bg-light">
                      <h5 className="mb-0">
                        <Award className="me-2" />
                        Top Bowler
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <h5>{selectedMatch.live_match?.top_bowler?.name || "N/A"}</h5>
                      <p className="mb-0">
                        {selectedMatch.live_match?.top_bowler?.wickets || 0}/
                        {selectedMatch.live_match?.top_bowler?.runs_conceded || 0} (
                        {selectedMatch.live_match?.top_bowler?.overs || "0.0"})
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        );
      case "commentary":
        return (
          <div>
            <h4 className="mb-4">Commentary</h4>
            {selectedMatch && (
              <ListGroup>
                {selectedMatch.live_match?.commentaries?.map((item: any, index: number) => (
                  <ListGroupItem key={index} className="d-flex align-items-center">
                    <Badge color="info" pill className="me-3">
                      {item.over}
                    </Badge>
                    <span>{item.commentary}</span>
                  </ListGroupItem>
                ))}
              </ListGroup>
            )}
          </div>
        );
      case "scorecard":
        return (
          <div>
            <h4 className="mb-4">Scorecard</h4>
            {selectedMatch && (
              <Row>
                <Col md={12}>
                  <h5 className="mb-4">Batting</h5>
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th>Batsman</th>
                        <th>Runs</th>
                        <th>Balls</th>
                        <th>4s</th>
                        <th>6s</th>
                        <th>Strike Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedMatch.live_match?.batsman?.map((batsman: any, index: number) => (
                        <tr key={index}>
                          <td>{batsman.name}</td>
                          <td>{batsman.runs}</td>
                          <td>{batsman.balls_faced}</td>
                          <td>{batsman.fours}</td>
                          <td>{batsman.sixes}</td>
                          <td>{batsman.strike_rate}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
                <Col md={12} className="mt-5">
                  <h5 className="mb-4">Bowling</h5>
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th>Bowler</th>
                        <th>Overs</th>
                        <th>Maidens</th>
                        <th>Runs</th>
                        <th>Wickets</th>
                        <th>Economy</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedMatch.live_match?.bowlers?.map((bowler: any, index: number) => (
                        <tr key={index}>
                          <td>{bowler.name}</td>
                          <td>{bowler.overs}</td>
                          <td>{bowler.maidens}</td>
                          <td>{bowler.runs_conceded}</td>
                          <td>{bowler.wickets}</td>
                          <td>{bowler.econ}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          </div>
        );
      case "squad":
        return (
          <div>
            <h4 className="mb-4">Squad</h4>
            <Row>
              {selectedMatch && (
                <>
                  <Col md={6}>
                    <h5>{selectedMatch.match_detail?.teama?.title}</h5>
                    <ListGroup>
                      {selectedMatch.match_squad?.teama?.squads?.map(
                        (player: any, index: number) => (
                          <ListGroupItem key={index}>{player.player?.title}</ListGroupItem>
                        )
                      )}
                    </ListGroup>
                  </Col>
                  <Col md={6}>
                    <h5>{selectedMatch.match_detail?.teamb?.title}</h5>
                    <ListGroup>
                      {selectedMatch.match_squad?.teamb?.squads?.map(
                        (player: any, index: number) => (
                          <ListGroupItem key={index}>{player.player?.title}</ListGroupItem>
                        )
                      )}
                    </ListGroup>
                  </Col>
                </>
              )}
            </Row>
          </div>
        );
      case "highlights":
        return (
          <div>
            <h4 className="mb-4">Highlights</h4>
            <ListGroup>
              {selectedMatch?.highlights?.map((highlight: any, index: number) => (
                <ListGroupItem key={index}>
                  <strong>{highlight.time}</strong>: {highlight.description}
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
        );
      case "news":
        return (
          <div>
            <h4 className="mb-4">News</h4>
            {selectedMatch?.news?.map((item: any, index: number) => (
              <Card key={index} className="mb-3">
                <CardBody>
                  <h5>{item.title}</h5>
                  <p>{item.content}</p>
                </CardBody>
              </Card>
            ))}
          </div>
        );
      case "matchInfo":
        return (
          <div>
            <h4 className="mb-4">Match Info</h4>
            {selectedMatch && (
              <Card>
                <CardBody>
                  <p>
                    <Clock className="me-2" />
                    <strong>Date & Time:</strong> {selectedMatch.match_detail?.date_start_ist}
                  </p>
                  <p>
                    <MapPin className="me-2" />
                    <strong>Venue:</strong> {selectedMatch.match_detail?.venue?.name}
                  </p>
                  <p>
                    <Award className="me-2" />
                    <strong>Toss:</strong> {selectedMatch.match_detail?.toss_text}
                  </p>
                  <h5 className="mt-4">Umpires</h5>
                  <p>{selectedMatch.match_detail?.umpires}</p>
                </CardBody>
              </Card>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="page-body">
      <Container fluid className="mt-4">
        <Row className="mb-4">
          {
            liveMatchesData?.isLoading?
              <div className="text-center">
                <Spinner color="primary" />
            </div>
          :
          
          liveMatchesData?.data?.[0]?.live_matches?.map((match: any) => (
            <Col key={match.id} md={4} onClick={() => setSelectedMatchId(match.id)}>
              <Card className="live-match-card">
                <CardBody>
                  <h5 className="btn-link" style={{textDecoration: 'none'}}>{match.title}</h5>
                  <p>{match.venue?.name}</p>
                  <Badge color="danger" pill>
                    {match.status_str}
                  </Badge>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

        {selectedMatch && (
          <Card className="mb-4 main-score-card">
            <CardBody>
              <h2 className="btn-link" style={{textDecoration: 'none'}}>{selectedMatch.match_detail?.competition?.title}</h2>
              <h3>
                Match Id {selectedMatch.match__id} • {selectedMatch.match_detail?.venue?.name}
              </h3>
              <Row className="align-items-center">
                <Col md={4}>
                  <h4>
                    {selectedMatch.match_detail?.teama?.title}:{" "}
                    {selectedMatch.live_match?.live_score_runs}/{selectedMatch.live_match?.live_score_wickets} (
                    {selectedMatch.live_match?.live_score_overs})
                  </h4>
                </Col>
                <Col md={4}>
                  <h4>
                    {selectedMatch.match_detail?.teamb?.title}:{" "}
                    {selectedMatch.match_detail?.game_state_str}
                  </h4>
                </Col>
                <Col md={4}>
                  <Badge color="danger" pill className="px-3 py-2">
                    {selectedMatch.match_detail?.toss_text}
                  </Badge>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}

        {
            liveMatchesData?.isLoading?
            <div className="text-center">
              <Spinner color="primary" />
          </div>:
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Nav tabs>
                    {[
                      { id: "summary", icon: <FileText />, label: "Summary" },
                      { id: "commentary", icon: <MessageCircle />, label: "Commentary" },
                      { id: "scorecard", icon: <FileText />, label: "Scorecard" },
                      { id: "squad", icon: <Users />, label: "Squad" },
                      { id: "highlights", icon: <Video />, label: "Highlights" },
                      { id: "news", icon: <Rss />, label: "News" },
                      { id: "matchInfo", icon: <Info />, label: "Match Info" },
                    ].map((tab) => (
                      <NavItem key={tab.id}>
                        <NavLink
                          className={activeTab === tab.id ? "active" : ""}
                          onClick={() => setActiveTab(tab.id)}
                        >
                          {tab.icon}
                          <span className="ms-2">{tab.label}</span>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTab} className="mt-3">
                    <TabPane tabId={activeTab}>{renderTabContent()}</TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="mb-3">
                <CardHeader>
                  <Button
                    color="link"
                    onClick={() => setShowPlaying11(!showPlaying11)}
                    className="d-flex justify-content-between align-items-center w-100"
                  >
                    Playing 11
                    {showPlaying11 ? <ChevronUp /> : <ChevronDown />}
                  </Button>
                </CardHeader>
                <CardBody className={showPlaying11 ? "" : "d-none"}>
                  {selectedMatch && (
                    <>
                      <h5>{selectedMatch.match_detail?.teama?.title}</h5>
                      <ListGroup flush>
                        {selectedMatch.match_squad?.teama?.squads?.map(
                          (player: any, index: number) => (
                            
                            <ListGroupItem key={index}>{player.player?.title}</ListGroupItem>
                          )
                        )}
                      </ListGroup>
                      <h5 className="mt-3">{selectedMatch.match_detail?.teamb?.title}</h5>
                      <ListGroup flush>
                        {selectedMatch.match_squad?.teamb?.squads?.map(
                          (player: any, index: number) => (
                            <ListGroupItem key={index}>{player.player?.title}</ListGroupItem>
                          )
                        )}
                      </ListGroup>
                    </>
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <Button
                    color="link"
                    onClick={() => setShowVenueDetails(!showVenueDetails)}
                    className="d-flex justify-content-between align-items-center w-100"
                  >
                    Venue Details
                    {showVenueDetails ? <ChevronUp /> : <ChevronDown />}
                  </Button>
                </CardHeader>
                <CardBody className={showVenueDetails ? "" : "d-none"}>
                  {selectedMatch && (
                    <>
                      <p>
                        <MapPin className="me-2" />
                        {selectedMatch.match_detail?.venue?.name}
                      </p>
                      <p>
                        <strong>Location:</strong> {selectedMatch.match_detail?.venue?.location}
                      </p>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    </div>
  );
};

export default LiveScoringDemo;