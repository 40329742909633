import axios from "axios";

// export const BACKEND_URL = "http://localhost:3000/api/v1";
// export const FRONTEND_URL = "http://localhost:3001";
export const BACKEND_URL = "/api/v1";
export const FRONTEND_URL = "https://console.prosportsapi.com";

interface axiosConf {
    url: any,
    method: string,
    data: any
    headers: { Authorization: string; };
}

export const api = async (pathname: string, method: string, body: any, formData=false, includeCredentials = false) => {
    const axiosConfig: axiosConf = {
        url: `${BACKEND_URL}${pathname}`,
        method: method,
        data: undefined,
        headers: {
            Authorization: ""
        }
    };
    if(body){
        if(formData){
          console.log("body------", body);
            const data = new FormData();
            for (const key in body) {
              if (body.hasOwnProperty(key)) {
                  data.append(key, body[key]);
              }
            }
            console.log('data', data);
            axiosConfig.data = data;
        }else{
            axiosConfig.data = body;
        }
    }
    if (includeCredentials) {
        const storedUser: any = localStorage.getItem("login-user");
        console.log(storedUser);
        const { token } = JSON.parse(storedUser);
        axiosConfig.headers = {
            'Authorization': `Token ${token}`
        };
    }
    console.log("axiosConfig", axiosConfig);

    return await axios(axiosConfig)
        .then((res) => res)
        .catch((e) => {
            console.log('inside: ', e);
            return e;
        });
};

export const changeText = (e: { target: { name: any; value: any } }, set: (arg0: any) => void, content: any, value?: any) => {
    set({...content, [e.target.name]: value?value:e.target.value})
}

export const convertToIST = (dateString: any) => {
  const date = new Date(dateString);
  console.log("date", date);
  // Convert to IST by adding 5 hours and 30 minutes
  const istOffset = 5.5 * 60 * 60 * 1000;
  const istDate = new Date(date.getTime() + istOffset);
  console.log("istDate", istDate);
  
  // Format the date in the desired format (e.g., DD-MM-YYYY HH:mm:ss)
  const formattedDate = istDate.toLocaleString("en-IN", {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'Asia/Kolkata'
  });
  console.log("formattedDate", formattedDate);
  
  return formattedDate;
};

export const Loading = () => {
    const loaderStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    };
  
    const circleStyle = {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      margin: '0 10px',
      backgroundColor: 'white',
      animation: 'circle1 1s ease-in-out infinite'
    };
  
    const circleStyleWithDelay = (delay: number) => ({
      ...circleStyle,
      animationDelay: `${delay}s`
    });
  
    return (
      <div style={loaderStyle}>
        <div style={circleStyle}></div>
        <div style={circleStyleWithDelay(0.2)}></div>
        <div style={circleStyleWithDelay(0.4)}></div>
        <div style={circleStyleWithDelay(0.6)}></div>
        <div style={circleStyleWithDelay(0.8)}></div>
        <style>
          {`
            @keyframes circle1 {
              0% {
                transform: scale(1);
                opacity: 1;
              }
              50% {
                transform: scale(1.5);
                opacity: 0.5;
              }
              100% {
                transform: scale(1);
                opacity: 1;
              }
            }
          `}
        </style>
      </div>
    );
  };

export const endpoints = [
  {
    method: 'GET',
    path: '/v1/seasons',
    description: 'Get all available seasons',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "seasons": [
          {
              "id": 1,
              "ref_season_id": "2016",
              "name": "2016",
              "competitions_url": "seasons/2016/competitions",
              "created_at": "2022-12-02T06:19:09.000Z",
              "updated_at": "2022-12-02T06:19:09.000Z"
          },
          {
              "id": 2,
              "ref_season_id": "201617",
              "name": "2016-17",
              "competitions_url": "seasons/201617/competitions",
              "created_at": "2022-12-02T06:19:09.000Z",
              "updated_at": "2022-12-02T06:19:09.000Z"
          },
          ...
      ]
  }
}`
  },
  {
    method: 'GET',
    path: '/v1/allseasons',
    description: 'Get details about a specific competition',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string',
      teams: 'array of objects'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "seasons": {
          "meta": {
              "data_version": "1.1.0",
              "created": "2022-01-28",
              "revision": 2
          },
          "info": {
              "balls_per_over": 6,
              "city": "Melbourne",
              "dates": [
                  "2022-01-28"
              ],
              "event": {
                  "name": "Big Bash League",
                  "stage": "Final"
              },
              "gender": "male",
              "match_type": "T20",
              "officials": {
                  "match_referees": [
                      "SJ Davis"
                  ],
                  "reserve_umpires": [
                      "GA Abood"
                  ],
                  "tv_umpires": [
                      "DM Koch"
                  ],
                  "umpires": [
                      "PJ Gillespie",
                      "SJ Nogajski"
                  ]
              },
              "outcome": {
                  "winner": "Perth Scorchers",
                  "by": {
                      "runs": 79
                  }
              },
              "overs": 20,
              "player_of_match": [
                  "LJ Evans"
              ],
              "players": {
                  "Perth Scorchers": [
                      "KR Patterson",
                      "JP Inglis",
                      "MR Marsh",
                      "C Munro",
                      "AJ Turner",
                      "LJ Evans",
                      "AC Agar",
                      "JA Richardson",
                      "AJ Tye",
                      "JP Behrendorff",
                      "P Hatzoglou"
                  ],
                  "Sydney Sixers": [
                      "JM Bird",
                      "H Kerr",
                      "NL Bertus",
                      "DP Hughes",
                      "MC Henriques",
                      "DT Christian",
                      "J Avendano",
                      "SA Abbott",
                      "BJ Dwarshuis",
                      "JS Lenton",
                      "NM Lyon",
                      "SNJ O'Keefe"
                  ]
              },
              "registry": {
                  "people": {
                      "AC Agar": "a2421394",
                      "AJ Turner": "ff1e12a0",
                      "AJ Tye": "7c7d63a2",
                      "BJ Dwarshuis": "e1b9f3a9",
                      "C Munro": "af2c687b",
                      "DM Koch": "6d10c034",
                      "DP Hughes": "f3982af9",
                      "DT Christian": "2a2e6343",
                      "GA Abood": "a4af5528",
                      "H Kerr": "f422728b",
                      "J Avendano": "0af4a90b",
                      "JA Richardson": "1ee08e9a",
                      "JM Bird": "bc773eeb",
                      "JP Behrendorff": "4933f499",
                      "JP Inglis": "989889ff",
                      "JS Lenton": "8820c9ef",
                      "KR Patterson": "e3bf8356",
                      "LJ Evans": "1c513d54",
                      "MC Henriques": "32198ae0",
                      "MR Marsh": "3d8feaf8",
                      "NL Bertus": "ebaeab6b",
                      "NM Lyon": "96a6a7ad",
                      "P Hatzoglou": "6f95e5fa",
                      "PJ Gillespie": "3e253a50",
                      "SA Abbott": "1a2676c5",
                      "SJ Davis": "bc71b0b2",
                      "SJ Nogajski": "9b3f9323",
                      "SNJ O'Keefe": "3540beff"
                  }
              },
              "season": "2021/22",
              "supersubs": {
                  "Sydney Sixers": "J Avendano"
              },
              "team_type": "club",
              "teams": [
                  "Perth Scorchers",
                  "Sydney Sixers"
              ],
              "toss": {
                  "decision": "field",
                  "winner": "Sydney Sixers"
              },
              "venue": "Docklands Stadium, Melbourne"
          },
          "innings": [
              {
                  "team": "Perth Scorchers",
                  "overs": [
                      {
                          "over": 0,
                          "deliveries": [
                              {
                                  "batter": "KR Patterson",
                                  "bowler": "SA Abbott",
                                  "non_striker": "JP Inglis",
                                  "runs": {
                                      "batter": 0,
                                      "extras": 0,
                                      "total": 0
                                  }
                              },
                              {
                                  "batter": "KR Patterson",
                                  "bowler": "SA Abbott",
                                  "non_striker": "JP Inglis",
                                  "runs": {
                                      "batter": 1,
                                      "extras": 0,
                                      "total": 1
                                  }
                              },
                              {
                                  "batter": "JP Inglis",
                                  "bowler": "SA Abbott",
                                  "non_striker": "KR Patterson",
                                  "runs": {
                                      "batter": 0,
                                      "extras": 0,
                                      "total": 0
                                  }
                              },
                              {
                                  "batter": "JP Inglis",
                                  "bowler": "SA Abbott",
                                  "non_striker": "KR Patterson",
                                  "runs": {
                                      "batter": 0,
                                      "extras": 0,
                                      "total": 0
                                  }
                              },
                              {
                                  "batter": "JP Inglis",
                                  "bowler": "SA Abbott",
                                  "non_striker": "KR Patterson",
                                  "runs": {
                                      "batter": 0,
                                      "extras": 0,
                                      "total": 0
                                  }
                              },
                              {
                                  "batter": "JP Inglis",
                                  "bowler": "SA Abbott",
                                  "non_striker": "KR Patterson",
                                  "runs": {
                                      "batter": 1,
                                      "extras": 0,
                                      "total": 1
                                  }
                              }
                          ]
                      },
                      {
                          "over": 1,
                          "deliveries": [
                              {
                                  "batter": "JP Inglis",
                                  "bowler": "JM Bird",
                                  "non_striker": "KR Patterson",
                                  "runs": {
                                      "batter": 4,
                                      "extras": 0,
                                      "total": 4
                                  }
                              },
                              {
                                  "batter": "JP Inglis",
                                  "bowler": "JM Bird",
                                  "non_striker": "KR Patterson",
                                  "runs": {
                                      "batter": 1,
                                      "extras": 0,
                                      "total": 1
                                  }
                              },
                              {
                                  "batter": "KR Patterson",
                                  "bowler": "JM Bird",
                                  "non_striker": "JP Inglis",
                                  "runs": {
                                      "batter": 0,
                                      "extras": 0,
                                      "total": 0
                                  }
                              },
                              {
                                  "batter": "KR Patterson",
                                  "bowler": "JM Bird",
                                  "non_striker": "JP Inglis",
                                  "runs": {
                                      "batter": 0,
                                      "extras": 0,
                                      "total": 0
                                  },
                                  "wickets": [
                                      {
                                          "player_out": "KR Patterson",
                                          "fielders": [
                                              {
                                                  "name": "JS Lenton"
                                              }
                                          ],
                                          "kind": "caught"
                                      }
                                  ]
                              },
                              {
                                  "batter": "JP Inglis",
                                  "bowler": "JM Bird",
                                  "non_striker": "MR Marsh",
                                  "runs": {
                                      "batter": 1,
                                      "extras": 0,
                                      "total": 1
                                  }
                              },
                              {
                                  "batter": "MR Marsh",
                                  "bowler": "JM Bird",
                                  "non_striker": "JP Inglis",
                                  "runs": {
                                      "batter": 0,
                                      "extras": 0,
                                      "total": 0
                                  }
                              }
                          ]
                      },
                      {
                          "over": 2,
                          "deliveries": [
                              {
                                  "batter": "JP Inglis",
                                  "bowler": "SA Abbott",
                                  "non_striker": "MR Marsh",
                                  "runs": {
                                      "batter": 0,
                                      "extras": 0,
                                      "total": 0
                                  }
                              },
                              {
                                  "batter": "JP Inglis",
                                  "bowler": "SA Abbott",
                                  "non_striker": "MR Marsh",
                                  "runs": {
                                      "batter": 4,
                                      "extras": 0,
                                      "total": 4
                                  }
                              },
                              {
                                  "batter": "JP Inglis",
                                  "bowler": "SA Abbott",
                                  "non_striker": "MR Marsh",
                                  "runs": {
                                      "batter": 0,
                                      "extras": 0,
                                      "total": 0
                                  }
                              },
                              {
                                  "batter": "JP Inglis",
                                  "bowler": "SA Abbott",
                                  "non_striker": "MR Marsh",
                                  "runs": {
                                      "batter": 1,
                                      "extras": 0,
                                      "total": 1
                                  }
                              },
                              {
                                  "batter": "MR Marsh",
                                  "bowler": "SA Abbott",
                                  "non_striker": "JP Inglis",
                                  "runs": {
                                      "batter": 0,
                                      "extras": 0,
                                      "total": 0
                                  }
                              },
                              {
                                  "batter": "MR Marsh",
                                  "bowler": "SA Abbott",
                                  "non_striker": "JP Inglis",
                                  "runs": {
                                      "batter": 0,
                                      "extras": 0,
                                      "total": 0
                                  }
                              }
                          ]
                      },
                      {
                          "over": 3,
                          "deliveries": [
                              {
                                  "batter": "JP Inglis",
                                  "bowler": "H Kerr",
                                  "non_striker": "MR Marsh",
                                  "runs": {
                                      "batter": 1,
                                      "extras": 0,
                                      "total": 1
                                  }
                              },
                              {
                                  "batter": "MR Marsh",
                                  "bowler": "H Kerr",
                                  "non_striker": "JP Inglis",
                                  "runs": {
                                      "batter": 0,
                                      "extras": 0,
                                      "total": 0
                                  }
                              },...`
  },
  {
    method: 'GET',
    path: '/v1/seasons/competitions',
    description: 'Get all available seasons competition',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "competitions": [
          {
              "id": 571,
              "season_id": 1,
              "ref_competition_id": 10517,
              "title": "Pakistan Women in England T20I Series",
              "abbr": "ci",
              "type": null,
              "category": "women",
              "match_format": null,
              "status": null,
              "season": null,
              "datestart": "2016-07-03",
              "dateend": "2016-07-07",
              "total_matches": "4",
              "total_rounds": "1",
              "total_teams": "3",
              "country": null,
              "game_format": null,
              "json": null,
              "created_at": "2024-04-30T11:20:17.000Z",
              "updated_at": "2024-04-30T11:20:17.000Z"
          },
          {
              "id": 570,
              "season_id": 1,
              "ref_competition_id": 10521,
              "title": "South Africa Women in Ireland T20I Series",
              "abbr": "ci",
              "type": null,
              "category": "women",
              "match_format": null,
              "status": null,
              "season": null,
              "datestart": "2016-08-04",
              "dateend": "2016-08-07",
              "total_matches": "2",
              "total_rounds": "1",
              "total_teams": "2",
              "country": null,
              "game_format": null,
              "json": null,
              "created_at": "2024-04-30T11:20:17.000Z",
              "updated_at": "2024-04-30T11:20:17.000Z"
          },
          {
              "id": 569,
              "season_id": 1,
              "ref_competition_id": 14721,
              "title": "Women's World T20",
              "abbr": "ICCT20WC 2016",
              "type": null,
              "category": "women",
              "match_format": null,
              "status": null,
              "season": null,
              "datestart": "2016-03-15",
              "dateend": "2016-04-03",
              "total_matches": "28",
              "total_rounds": "7",
              "total_teams": "10",
              "country": null,
              "game_format": null,
              "json": null,
              "created_at": "2024-04-30T11:20:17.000Z",
              "updated_at": "2024-04-30T11:20:17.000Z"
          },...`
  },
  {
    method: 'GET',
    path: '/v1/competitions',
    description: 'Get all available competition',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "competitions": [
          {
              "id": 1898,
              "season_id": 16,
              "ref_competition_id": 128590,
              "title": "ICC Women's T20 World Cup",
              "abbr": "ICC Women's T20 World Cup",
              "type": null,
              "category": "women",
              "match_format": null,
              "status": null,
              "season": null,
              "datestart": "2024-10-03",
              "dateend": "2024-10-20",
              "total_matches": "10",
              "total_rounds": "0",
              "total_teams": "12",
              "country": null,
              "game_format": null,
              "json": null,
              "created_at": "2024-06-20T14:13:18.000Z",
              "updated_at": "2024-06-20T14:13:18.000Z"
          },
          {
              "id": 1897,
              "season_id": 16,
              "ref_competition_id": 128595,
              "title": "ECI-W Italy, Brescia",
              "abbr": "ECI-W Italy",
              "type": null,
              "category": "women",
              "match_format": null,
              "status": null,
              "season": null,
              "datestart": "2024-05-11",
              "dateend": "2024-05-12",
              "total_matches": "5",
              "total_rounds": "0",
              "total_teams": "2",
              "country": null,
              "game_format": null,
              "json": null,
              "created_at": "2024-06-20T14:13:18.000Z",
              "updated_at": "2024-06-20T14:13:18.000Z"
          },
          {
              "id": 1896,
              "season_id": 16,
              "ref_competition_id": 128654,
              "title": "Estonia-Malta Women T10",
              "abbr": "ECI-W T10",
              "type": null,
              "category": "women",
              "match_format": null,
              "status": null,
              "season": null,
              "datestart": "2024-05-25",
              "dateend": "2024-05-26",
              "total_matches": "5",
              "total_rounds": "1",
              "total_teams": "2",
              "country": null,
              "game_format": null,
              "json": null,
              "created_at": "2024-06-20T14:13:18.000Z",
              "updated_at": "2024-06-20T14:13:18.000Z"
          },...`
  },
  {
    method: 'GET',
    path: '/v1/competition-overview/{competition_id}',
    description: 'Get available competition overview',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "competition": {
          "id": 1898,
          "season_id": 16,
          "ref_competition_id": 128590,
          "title": "ICC Women's T20 World Cup",
          "abbr": "ICC Women's T20 World Cup",
          "type": null,
          "category": "women",
          "match_format": null,
          "status": null,
          "season": null,
          "datestart": "2024-10-03",
          "dateend": "2024-10-20",
          "total_matches": "10",
          "total_rounds": "0",
          "total_teams": "12",
          "country": null,
          "game_format": null,
          "json": null,
          "created_at": "2024-06-20T14:13:18.000Z",
          "updated_at": "2024-06-20T14:13:18.000Z",
          "rounds": []
      }
  }
}`
  },
 {
    method: 'GET',
    path: '/v1/competitions/matches/{competition_id}',
    description: 'Get available competition match',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "data": [
          {
              "id": 760,
              "ref_match_id": 75974,
              "ref_liveline_match_id": null,
              "title": "Sharjah vs Dubai",
              "short_title": "SHA vs DUB",
              "subtitle": null,
              "format": "4",
              "format_str": "List A",
              "status": "1",
              "status_str": "Scheduled",
              "status_note": "",
              "verified": "false",
              "pre_squad": "true",
              "odds_available": "false",
              "game_state": 0,
              "game_state_str": "Default",
              "domestic": "1",
              "competition_id": 531,
              "date_start": "2024-05-02T06:30:00.000Z",
              "date_end": "2024-05-03T00:30:00.000Z",
              "date_start_ist": "2024-05-02T12:00:00.000Z",
              "date_end_ist": "2024-05-03T06:00:00.000Z",
              "venue_id": 99,
              "umpires": null,
              "referee": null,
              "equation": null,
              "live": null,
              "result": null,
              "result_type": null,
              "win_margin": null,
              "winning_team_id": null,
              "commentary": null,
              "wagon": null,
              "latest_inning_number": null,
              "presquad_time": null,
              "verify_time": null,
              "toss_text": null,
              "toss_winner_id": null,
              "toss_decision": null,
              "is_scorecard_done": 0,
              "liveline_json": {
                  "match_id": 75974,
                  "title": "Sharjah vs Dubai",
                  "short_title": "SHA vs DUB",
                  "subtitle": "Match 7",
                  "match_number": "7",
                  "format": 4,
                  "format_str": "List A",
                  "status": 1,
                  "status_str": "Scheduled",
                  "status_note": "",
                  "verified": "false",
                  "pre_squad": "true",
                  "odds_available": "false",
                  "game_state": 0,
                  "game_state_str": "Default",
                  "domestic": "1",
                  "competition": {
                      "cid": 128572,
                      "title": "Emirates D50",
                      "abbr": "ED50",
                      "type": "tournament",
                      "category": "domestic",
                      "match_format": "lista",
                      "season": "2024",
                      "status": "live",
                      "datestart": "2024-04-24",
                      "dateend": "2024-05-06",
                      "country": "ae",
                      "total_matches": "7",
                      "total_rounds": "1",
                      "total_teams": "6"
                  },
                  "teama": {
                      "team_id": 116225,
                      "name": "Sharjah",
                      "short_name": "SHA",
                      "logo_url": "https://images.entitysport.com/assets/uploads/2023/05/SHA-CR2@2x.png"
                  },
                  "teamb": {
                      "team_id": 116224,
                      "name": "Dubai",
                      "short_name": "DUB",
                      "logo_url": "https://images.entitysport.com/assets/uploads/2024/02/Dubai.png"
                  },
                  "date_start": "2024-05-02 12:00:00",
                  "date_end": "2024-05-03 06:00:00",
                  "timestamp_start": 1714651200,
                  "timestamp_end": 1714716000,
                  "date_start_ist": "2024-05-02 17:30:00",
                  "date_end_ist": "2024-05-03 11:30:00",
                  "venue": {
                      "venue_id": "1",
                      "name": "TBA",
                      "location": "TBA",
                      "country": "",
                      "timezone": ""
                  },
                  "umpires": "",
                  "referee": "",
                  "equation": "",
                  "live": "",
                  "result": "",
                  "result_type": "",
                  "win_margin": "",
                  "winning_team_id": 0,
                  "commentary": 1,
                  "wagon": 0,
                  "latest_inning_number": 0,
                  "presquad_time": "2024-04-26 19:40:45",
                  "verify_time": "",
                  "match_dls_affected": "false",
                  "day": "0",
                  "session": "0",
                  "weather": [],
                  "pitch": {
                      "pitch_condition": "",
                      "batting_condition": "",
                      "pace_bowling_condition": "",
                      "spine_bowling_condition": ""
                  },
                  "toss": {
                      "winner": 0,
                      "decision": 0
                  }
              },
              "json": null,
              "created_at": "2024-04-30T11:02:28.000Z",
              "updated_at": "2024-04-30T11:02:28.000Z",
              "teama": {
                  "id": 133,
                  "sport_id": 1,
                  "ref_team_id": 116225,
                  "ref_liveline_team_id": null,
                  "title": "Sharjah",
                  "thumb_url": "https://images.entitysport.com/assets/uploads/2020/12/ddd-120x120.png",
                  "logo_url": "https://images.entitysport.com/assets/uploads/2020/12/ddd-120x120.png",
                  "type": null,
                  "country": null,
                  "alt_name": null,
                  "abbr": null,
                  "created_at": "2022-12-17T07:57:34.000Z",
                  "updated_at": "2022-12-17T07:57:34.000Z"
              },
              "teamb": {
                  "id": 129,
                  "sport_id": 1,
                  "ref_team_id": 116224,
                  "ref_liveline_team_id": null,
                  "title": "Dubai",
                  "thumb_url": "https://images.entitysport.com/assets/uploads/2020/12/eee-120x120.png",
                  "logo_url": "https://images.entitysport.com/assets/uploads/2020/12/eee-120x120.png",
                  "type": null,
                  "country": null,
                  "alt_name": null,
                  "abbr": null,
                  "created_at": "2022-12-16T05:25:11.000Z",
                  "updated_at": "2022-12-16T05:25:11.000Z"
              },
              "venue": {
                  "id": 99,
                  "ref_venue_id": 1,
                  "name": "TBA",
                  "location": "TBA",
                  "country": "",
                  "timezone": -12,
                  "created_at": "2022-12-16T04:29:27.000Z",
                  "updated_at": "2022-12-16T04:29:27.000Z"
              },
              "competition": {
                  "id": 531,
                  "season_id": null,
                  "ref_competition_id": 128572,
                  "title": "Emirates D50",
                  "abbr": "ED50",
                  "type": null,
                  "category": "domestic",
                  "match_format": null,
                  "status": "live",
                  "season": null,
                  "datestart": "2024-04-24",
                  "dateend": "2024-05-06",
                  "total_matches": "7",
                  "total_rounds": "1",
                  "total_teams": "6",
                  "country": "ae",
                  "game_format": null,
                  "json": null,
                  "created_at": "2024-04-30T10:37:21.000Z",
                  "updated_at": "2024-04-30T10:37:21.000Z"
              }
          },
          {
              "id": 712,
              "ref_match_id": 75973,
              "ref_liveline_match_id": null,
              "title": "Fujairah vs Emirates Blues",
              "short_title": "FUJ vs EMB",
              "subtitle": null,
              "format": "4",
              "format_str": "List A",
              "status": "1",
              "status_str": "Scheduled",
              "status_note": "",
              "verified": "false",
              "pre_squad": "true",
              "odds_available": "false",
              "game_state": 0,
              "game_state_str": "Default",
              "domestic": "1",
              "competition_id": 531,
              "date_start": "2024-05-01T06:30:00.000Z",
              "date_end": "2024-05-02T00:30:00.000Z",
              "date_start_ist": "2024-05-01T12:00:00.000Z",
              "date_end_ist": "2024-05-02T06:00:00.000Z",
              "venue_id": 111,
              "umpires": null,
              "referee": null,
              "equation": null,
              "live": null,
              "result": null,
              "result_type": null,
              "win_margin": null,
              "winning_team_id": null,
              "commentary": null,
              "wagon": null,
              "latest_inning_number": null,
              "presquad_time": null,
              "verify_time": null,
              "toss_text": null,
              "toss_winner_id": null,
              "toss_decision": null,
              "is_scorecard_done": 0,
              "liveline_json": {
                  "match_id": 75973,
                  "title": "Fujairah vs Emirates Blues",
                  "short_title": "FUJ vs EMB",
                  "subtitle": "Match 6",
                  "match_number": "6",
                  "format": 4,
                  "format_str": "List A",
                  "status": 1,
                  "status_str": "Scheduled",
                  "status_note": "",
                  "verified": "false",
                  "pre_squad": "true",
                  "odds_available": "false",
                  "game_state": 0,
                  "game_state_str": "Default",
                  "domestic": "1",
                  "competition": {
                      "cid": 128572,
                      "title": "Emirates D50",
                      "abbr": "ED50",
                      "type": "tournament",
                      "category": "domestic",
                      "match_format": "lista",
                      "season": "2024",
                      "status": "live",
                      "datestart": "2024-04-24",
                      "dateend": "2024-05-06",
                      "country": "ae",
                      "total_matches": "7",
                      "total_rounds": "1",
                      "total_teams": "6"
                  },
                  "teama": {
                      "team_id": 116223,
                      "name": "Fujairah",
                      "short_name": "FUJ",
                      "logo_url": "https://images.entitysport.com/assets/uploads/2024/02/Fujairah.png"
                  },
                  "teamb": {
                      "team_id": 73292,
                      "name": "Emirates Blues",
                      "short_name": "EMB",
                      "logo_url": "https://images.entitysport.com/assets/uploads/2021/12/EMB.jpg"
                  },
                  "date_start": "2024-05-01 12:00:00",
                  "date_end": "2024-05-02 06:00:00",
                  "timestamp_start": 1714564800,
                  "timestamp_end": 1714629600,
                  "date_start_ist": "2024-05-01 17:30:00",
                  "date_end_ist": "2024-05-02 11:30:00",
                  "venue": {
                      "venue_id": "1213457",
                      "name": "Malek Cricket Stadium 2",
                      "location": "Abu Dhabi",
                      "country": "United Arab Emirates",
                      "timezone": ""
                  },
                  "umpires": "",
                  "referee": "",
                  "equation": "",
                  "live": "",
                  "result": "",
                  "result_type": "",
                  "win_margin": "",
                  "winning_team_id": 0,
                  "commentary": 1,
                  "wagon": 0,
                  "latest_inning_number": 0,
                  "presquad_time": "2024-04-26 19:24:41",
                  "verify_time": "",
                  "match_dls_affected": "false",
                  "day": "0",
                  "session": "0",
                  "weather": {
                      "weather": "Clear",
                      "weather_desc": "clear sky",
                      "temp": 28.68,
                      "humidity": 46,
                      "visibility": 10000,
                      "wind_speed": "7.14",
                      "clouds": 0
                  },
                  "pitch": {
                      "pitch_condition": "Fast & Bounce",
                      "batting_condition": "Average",
                      "pace_bowling_condition": "Pace & Bounce",
                      "spine_bowling_condition": "Average"
                  },
                  "toss": {
                      "winner": 0,
                      "decision": 0
                  }
              },
              "json": null,
              "created_at": "2024-04-30T10:57:09.000Z",
              "updated_at": "2024-04-30T10:57:09.000Z",
              "teama": {
                  "id": 128,
                  "sport_id": 1,
                  "ref_team_id": 116223,
                  "ref_liveline_team_id": null,
                  "title": "Fujairah",
                  "thumb_url": "https://images.entitysport.com/assets/uploads/2020/12/bbb-120x120.png",
                  "logo_url": "https://images.entitysport.com/assets/uploads/2020/12/bbb-120x120.png",
                  "type": null,
                  "country": null,
                  "alt_name": null,
                  "abbr": null,
                  "created_at": "2022-12-16T05:25:11.000Z",
                  "updated_at": "2022-12-16T05:25:11.000Z"
              },
              "teamb": {
                  "id": 124,
                  "sport_id": 1,
                  "ref_team_id": 73292,
                  "ref_liveline_team_id": null,
                  "title": "Emirates Blues",
                  "thumb_url": "https://images.entitysport.com/assets/uploads/2021/12/EMB.jpg",
                  "logo_url": "https://images.entitysport.com/assets/uploads/2021/12/EMB.jpg",
                  "type": null,
                  "country": null,
                  "alt_name": null,
                  "abbr": null,
                  "created_at": "2022-12-16T05:24:23.000Z",
                  "updated_at": "2022-12-16T05:24:23.000Z"
              },
              "venue": {
                  "id": 111,
                  "ref_venue_id": 1213457,
                  "name": "Malek Cricket Stadium 2",
                  "location": "Abu Dhabi",
                  "country": "United Arab Emirates",
                  "timezone": 0,
                  "created_at": "2024-04-30T10:37:21.000Z",
                  "updated_at": "2024-04-30T10:37:21.000Z"
              },
              "competition": {
                  "id": 531,
                  "season_id": null,
                  "ref_competition_id": 128572,
                  "title": "Emirates D50",
                  "abbr": "ED50",
                  "type": null,
                  "category": "domestic",
                  "match_format": null,
                  "status": "live",
                  "season": null,
                  "datestart": "2024-04-24",
                  "dateend": "2024-05-06",
                  "total_matches": "7",
                  "total_rounds": "1",
                  "total_teams": "6",
                  "country": "ae",
                  "game_format": null,
                  "json": null,
                  "created_at": "2024-04-30T10:37:21.000Z",
                  "updated_at": "2024-04-30T10:37:21.000Z"
              }
          },...],
          "page_data": {
          "current_page": 1,
          "last_page": 1,
          "per_page": 10,
          "total": 3
      }`
  },
  {
    method: 'GET',
    path: '/v1/competitions/teams/{competitionId}',
    description: 'Get available team match',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "teams": [
          {
              "id": 124,
              "sport_id": 1,
              "ref_team_id": 73292,
              "ref_liveline_team_id": null,
              "title": "Emirates Blues",
              "thumb_url": "https://images.entitysport.com/assets/uploads/2021/12/EMB.jpg",
              "logo_url": "https://images.entitysport.com/assets/uploads/2021/12/EMB.jpg",
              "type": null,
              "country": null,
              "alt_name": null,
              "abbr": null,
              "created_at": "2022-12-16T05:24:23.000Z",
              "updated_at": "2022-12-16T05:24:23.000Z"
          },
          {
              "id": 128,
              "sport_id": 1,
              "ref_team_id": 116223,
              "ref_liveline_team_id": null,
              "title": "Fujairah",
              "thumb_url": "https://images.entitysport.com/assets/uploads/2020/12/bbb-120x120.png",
              "logo_url": "https://images.entitysport.com/assets/uploads/2020/12/bbb-120x120.png",
              "type": null,
              "country": null,
              "alt_name": null,
              "abbr": null,
              "created_at": "2022-12-16T05:25:11.000Z",
              "updated_at": "2022-12-16T05:25:11.000Z"
          },
          {
              "id": 129,
              "sport_id": 1,
              "ref_team_id": 116224,
              "ref_liveline_team_id": null,
              "title": "Dubai",
              "thumb_url": "https://images.entitysport.com/assets/uploads/2020/12/eee-120x120.png",
              "logo_url": "https://images.entitysport.com/assets/uploads/2020/12/eee-120x120.png",
              "type": null,
              "country": null,
              "alt_name": null,
              "abbr": null,
              "created_at": "2022-12-16T05:25:11.000Z",
              "updated_at": "2022-12-16T05:25:11.000Z"
          },
          {
              "id": 133,
              "sport_id": 1,
              "ref_team_id": 116225,
              "ref_liveline_team_id": null,
              "title": "Sharjah",
              "thumb_url": "https://images.entitysport.com/assets/uploads/2020/12/ddd-120x120.png",
              "logo_url": "https://images.entitysport.com/assets/uploads/2020/12/ddd-120x120.png",
              "type": null,
              "country": null,
              "alt_name": null,
              "abbr": null,
              "created_at": "2022-12-17T07:57:34.000Z",
              "updated_at": "2022-12-17T07:57:34.000Z"
          },
          {
              "id": 147,
              "sport_id": 1,
              "ref_team_id": 116220,
              "ref_liveline_team_id": null,
              "title": "Abu Dhabi",
              "thumb_url": "Abu.jpg",
              "logo_url": "Abu.jpg",
              "type": null,
              "country": null,
              "alt_name": null,
              "abbr": null,
              "created_at": "2024-04-30T10:37:19.000Z",
              "updated_at": "2024-04-30T10:37:19.000Z"
          }
      ]
  }
}`
  },
  {
    method: 'GET',
    path: '/v1/competitions/standings/{competitionId}',
    description: 'Get available competion standing',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "standing_type": "per_round",
      "standings": [
          {
              "round": {
                  "id": 2363,
                  "order": 1
              },
              "standings": [
                  {
                      "team_id": "128",
                      "played": "5",
                      "win": "5",
                      "draw": "0",
                      "loss": null,
                      "nr": "0",
                      "overfor": "82.4",
                      "runfor": "534",
                      "overagainst": "100",
                      "runagainst": "477",
                      "netrr": "1.343",
                      "points": "10",
                      "quality": "false"
                  },
                  {
                      "team_id": "147",
                      "played": "2",
                      "win": "2",
                      "draw": "0",
                      "loss": null,
                      "nr": "0",
                      "overfor": "150",
                      "runfor": "1003",
                      "overagainst": "150",
                      "runagainst": "777",
                      "netrr": "0.972",
                      "points": "4",
                      "quality": "false"
                  },
                  {
                      "team_id": "133",
                      "played": "3",
                      "win": "3",
                      "draw": "0",
                      "loss": null,
                      "nr": "0",
                      "overfor": "90",
                      "runfor": "554",
                      "overagainst": "72.3",
                      "runagainst": "493",
                      "netrr": "1.010",
                      "points": "6",
                      "quality": "false"
                  },
                  {
                      "team_id": "124",
                      "played": "1",
                      "win": "1",
                      "draw": "0",
                      "loss": null,
                      "nr": "0",
                      "overfor": "150",
                      "runfor": "721",
                      "overagainst": "132.4",
                      "runagainst": "1048",
                      "netrr": "-2.271",
                      "points": "2",
                      "quality": "false"
                  },
                  {
                      "team_id": "129",
                      "played": "0",
                      "win": "0",
                      "draw": "0",
                      "loss": null,
                      "nr": "0",
                      "overfor": "40",
                      "runfor": "245",
                      "overagainst": "40",
                      "runagainst": "307",
                      "netrr": "-1.733",
                      "points": "0",
                      "quality": "false"
                  },
                  {
                      "team_id": "411",
                      "played": "4",
                      "win": "4",
                      "draw": "0",
                      "loss": null,
                      "nr": "0",
                      "overfor": "132.3",
                      "runfor": "827",
                      "overagainst": "150",
                      "runagainst": "782",
                      "netrr": "0.837",
                      "points": "8",
                      "quality": "false"
                  }
              ]
          }
      ]
  }
}`
  },
  {
    method: 'GET',
    path: '/v1/matches',
    description: 'Get all available matches',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "data": [
          {
              "id": 944,
              "ref_match_id": 75169,
              "ref_liveline_match_id": null,
              "title": "Glamorgan vs Northamptonshire",
              "short_title": "GLA vs NOR",
              "subtitle": null,
              "format": "5",
              "format_str": "First Class",
              "status": "1",
              "status_str": "Scheduled",
              "status_note": "Starts at 11:00 local time",
              "verified": "false",
              "pre_squad": "false",
              "odds_available": "false",
              "game_state": 0,
              "game_state_str": "Default",
              "domestic": "1",
              "competition_id": 552,
              "date_start": "2024-06-23T04:30:00.000Z",
              "date_end": "2024-06-28T04:30:00.000Z",
              "date_start_ist": "2024-06-23T10:00:00.000Z",
              "date_end_ist": "2024-06-28T10:00:00.000Z",
              "venue_id": 158,
              "umpires": null,
              "referee": null,
              "equation": null,
              "live": null,
              "result": null,
              "result_type": null,
              "win_margin": null,
              "winning_team_id": null,
              "commentary": null,
              "wagon": null,
              "latest_inning_number": null,
              "presquad_time": null,
              "verify_time": null,
              "toss_text": null,
              "toss_winner_id": null,
              "toss_decision": null,
              "is_scorecard_done": 0,
              "liveline_json": {
                  "match_id": 75169,
                  "title": "Glamorgan vs Northamptonshire",
                  "short_title": "GLA vs NOR",
                  "subtitle": "29th Match",
                  "match_number": "29",
                  "format": 5,
                  "format_str": "First Class",
                  "status": 1,
                  "status_str": "Scheduled",
                  "status_note": "Starts at 11:00 local time",
                  "verified": "false",
                  "pre_squad": "false",
                  "odds_available": "false",
                  "game_state": 0,
                  "game_state_str": "Default",
                  "domestic": "1",
                  "competition": {
                      "cid": 128542,
                      "title": "Vitality County Championship Division Two",
                      "abbr": "County Championship DIV 2nd",
                      "type": "tournament",
                      "category": "domestic",
                      "match_format": "firstclass",
                      "season": "2024",
                      "status": "live",
                      "datestart": "2024-04-05",
                      "dateend": "2024-09-26",
                      "country": "en",
                      "total_matches": "56",
                      "total_rounds": "1",
                      "total_teams": "8"
                  },
                  "teama": {
                      "team_id": 7020,
                      "name": "Glamorgan",
                      "short_name": "GLA",
                      "logo_url": "https://images.entitysport.com/assets/uploads/2023/07/glamorgan-logo.png"
                  },
                  "teamb": {
                      "team_id": 7038,
                      "name": "Northamptonshire",
                      "short_name": "NOR",
                      "logo_url": "https://images.entitysport.com/assets/uploads/2023/07/northamptonshire-logo.png"
                  },
                  "date_start": "2024-06-23 10:00:00",
                  "date_end": "2024-06-28 10:00:00",
                  "timestamp_start": 1719136800,
                  "timestamp_end": 1719568800,
                  "date_start_ist": "2024-06-23 15:30:00",
                  "date_end_ist": "2024-06-28 15:30:00",
                  "venue": {
                      "venue_id": "7",
                      "name": "Sophia Gardens",
                      "location": "Cardiff",
                      "country": "England",
                      "timezone": ""
                  },
                  "umpires": "",
                  "referee": "",
                  "equation": "",
                  "live": "",
                  "result": "",
                  "result_type": "",
                  "win_margin": "",
                  "winning_team_id": 0,
                  "commentary": 0,
                  "wagon": 0,
                  "latest_inning_number": 0,
                  "presquad_time": "",
                  "verify_time": "",
                  "match_dls_affected": "false",
                  "day": "0",
                  "session": "0",
                  "weather": [],
                  "pitch": {
                      "pitch_condition": "",
                      "batting_condition": "Average",
                      "pace_bowling_condition": "Average",
                      "spine_bowling_condition": "Average"
                  },
                  "toss": {
                      "winner": 0,
                      "decision": 0
                  }
              },
              "json": null,
              "created_at": "2024-06-20T14:11:44.000Z",
              "updated_at": "2024-06-20T14:11:44.000Z",
              "teama": {
                  "id": 394,
                  "sport_id": 1,
                  "ref_team_id": 7020,
                  "ref_liveline_team_id": null,
                  "title": "Glamorgan",
                  "thumb_url": "glamorgan-logo.png",
                  "logo_url": "glamorgan-logo.png",
                  "type": null,
                  "country": null,
                  "alt_name": null,
                  "abbr": null,
                  "created_at": "2024-04-30T11:08:03.000Z",
                  "updated_at": "2024-04-30T11:08:03.000Z"
              },
              "teamb": {
                  "id": 449,
                  "sport_id": 1,
                  "ref_team_id": 7038,
                  "ref_liveline_team_id": null,
                  "title": "Northamptonshire",
                  "thumb_url": "northamptonshire-logo.png",
                  "logo_url": "northamptonshire-logo.png",
                  "type": null,
                  "country": null,
                  "alt_name": null,
                  "abbr": null,
                  "created_at": "2024-06-20T14:08:56.000Z",
                  "updated_at": "2024-06-20T14:08:56.000Z"
              },
              "venue": {
                  "id": 158,
                  "ref_venue_id": 7,
                  "name": "Sophia Gardens",
                  "location": "Cardiff",
                  "country": "England",
                  "timezone": 0,
                  "created_at": "2024-06-20T14:08:49.000Z",
                  "updated_at": "2024-06-20T14:08:49.000Z"
              },
              "competition": {
                  "id": 552,
                  "season_id": null,
                  "ref_competition_id": 128542,
                  "title": "Vitality County Championship Division Two",
                  "abbr": "County Championship DIV 2nd",
                  "type": null,
                  "category": "domestic",
                  "match_format": null,
                  "status": "live",
                  "season": null,
                  "datestart": "2024-04-05",
                  "dateend": "2024-09-26",
                  "total_matches": "56",
                  "total_rounds": "1",
                  "total_teams": "8",
                  "country": "en",
                  "game_format": null,
                  "json": null,
                  "created_at": "2024-04-30T11:06:38.000Z",
                  "updated_at": "2024-04-30T11:06:38.000Z"
              }
          },...]`
  },
  {
    method: 'GET',
    path: '/v1/matches/scorecard/{matchId}',
    description: 'Get available match scorecard',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "match": {
          "id": 950,
          "ref_match_id": 72869,
          "ref_liveline_match_id": null,
          "title": "Afghanistan vs India",
          "short_title": "AFG vs IND",
          "subtitle": null,
          "format": "3",
          "format_str": "T20I",
          "status": "3",
          "status_str": "Live",
          "status_note": "India elected to bat",
          "verified": "false",
          "pre_squad": "true",
          "odds_available": "false",
          "game_state": 3,
          "game_state_str": "Play Ongoing",
          "domestic": "0",
          "competition_id": null,
          "date_start": "2024-06-20T09:00:00.000Z",
          "date_end": "2024-06-20T19:00:00.000Z",
          "date_start_ist": "2024-06-20T14:30:00.000Z",
          "date_end_ist": "2024-06-21T00:30:00.000Z",
          "venue_id": 174,
          "umpires": "Paul Reiffel (Australia), Rod Tucker (Australia), Allahuddien Paleker (South Africa, TV)",
          "referee": "David Boon (Australia)",
          "equation": "",
          "live": "India elected to bat",
          "result": "",
          "result_type": "",
          "win_margin": "",
          "winning_team_id": null,
          "commentary": "1",
          "wagon": "1",
          "latest_inning_number": null,
          "presquad_time": null,
          "verify_time": null,
          "toss_text": "India elected to bat",
          "toss_winner_id": "18",
          "toss_decision": "1",
          "is_scorecard_done": 0,
          "liveline_json": null,
          "json": {
              "match_id": 72869,
              "title": "Afghanistan vs India",
              "short_title": "AFG vs IND",
              "subtitle": "43 Match, Super 8 Group 1",
              "match_number": "43",
              "format": 3,
              "format_str": "T20I",
              "status": 3,
              "status_str": "Live",
              "status_note": "India elected to bat",
              "verified": "false",
              "pre_squad": "true",
              "odds_available": "false",
              "game_state": 3,
              "game_state_str": "Play Ongoing",
              "domestic": "0",
              "competition": {
                  "cid": 128414,
                  "title": "ICC Men's T20 World Cup",
                  "abbr": "T20 WC-2024",
                  "type": "tournament",
                  "category": "international",
                  "match_format": "t20i",
                  "season": "2024",
                  "status": "live",
                  "datestart": "2024-06-01",
                  "dateend": "2024-06-29",
                  "country": "int",
                  "total_matches": "55",
                  "total_rounds": "6",
                  "total_teams": "20"
              },
              "teama": {
                  "team_id": 498,
                  "name": "Afghanistan",
                  "short_name": "AFG",
                  "logo_url": "https://images.entitysport.com/assets/uploads/2023/07/Afghanistan-3.png",
                  "scores_full": ""
              },
              "teamb": {
                  "team_id": 25,
                  "name": "India",
                  "short_name": "IND",
                  "logo_url": "https://images.entitysport.com/assets/uploads//2023/01/india-4.png",
                  "scores_full": "*75/3 (9.3 ov)",
                  "scores": "75/3",
                  "overs": "9.3"
              },
              "date_start": "2024-06-20 14:30:00",
              "date_end": "2024-06-21 00:30:00",
              "timestamp_start": 1718893800,
              "timestamp_end": 1718929800,
              "date_start_ist": "2024-06-20 20:00:00",
              "date_end_ist": "2024-06-21 06:00:00",
              "venue": {
                  "venue_id": "36",
                  "name": "Kensington Oval, Bridgetown",
                  "location": "Barbados",
                  "country": "West Indies",
                  "timezone": ""
              },
              "umpires": "Paul Reiffel (Australia), Rod Tucker (Australia), Allahuddien Paleker (South Africa, TV)",
              "referee": "David Boon (Australia)",
              "equation": "",
              "live": "India elected to bat",
              "result": "",
              "result_type": "",
              "win_margin": "",
              "winning_team_id": 0,
              "commentary": 1,
              "wagon": 1,
              "latest_inning_number": 1,
              "presquad_time": "2024-06-17 21:42:17",
              "verify_time": "",
              "match_dls_affected": "false",
              "day": "0",
              "session": "0",
              "weather": {
                  "weather": "Clouds",
                  "weather_desc": "overcast clouds",
                  "temp": 29.4,
                  "humidity": 71,
                  "visibility": 10000,
                  "wind_speed": "16.13",
                  "clouds": 97
              },
              "pitch": {
                  "pitch_condition": "Green Pitch",
                  "batting_condition": "Average",
                  "pace_bowling_condition": "Pace & Bounce",
                  "spine_bowling_condition": "Average"
              },
              "toss": {
                  "text": "India elected to bat",
                  "winner": 25,
                  "decision": 1
              }
          },
          "created_at": "2024-06-20T14:13:05.000Z",
          "updated_at": "2024-06-20T15:15:54.000Z",
          "competition": null,
          "teama": {
              "id": 22,
              "sport_id": 1,
              "ref_team_id": 498,
              "ref_liveline_team_id": 15,
              "title": "Afghanistan",
              "thumb_url": "Afghanistan-3.png",
              "logo_url": "Afghanistan-3.png",
              "type": null,
              "country": null,
              "alt_name": null,
              "abbr": null,
              "created_at": "2022-11-28T04:23:16.000Z",
              "updated_at": "2024-06-20T14:11:26.000Z"
          },
          "teamb": {
              "id": 18,
              "sport_id": 1,
              "ref_team_id": 25,
              "ref_liveline_team_id": 1,
              "title": "India",
              "thumb_url": "India.png",
              "logo_url": "India.png",
              "type": null,
              "country": null,
              "alt_name": null,
              "abbr": null,
              "created_at": "2022-11-28T04:23:16.000Z",
              "updated_at": "2023-02-25T05:32:53.000Z"
          },
          "venue": {
              "id": 174,
              "ref_venue_id": 36,
              "name": "Kensington Oval, Bridgetown",
              "location": "Barbados",
              "country": "West Indies",
              "timezone": 0,
              "created_at": "2024-06-20T14:10:55.000Z",
              "updated_at": "2024-06-20T14:10:55.000Z"
          },
          "livelineData": null
      }
  }
}`
  },
  {
    method: 'GET',
    path: '/v1/matches/details/{matchId}',
    description: 'Get match details',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "match": {
          "id": 942,
          "ref_match_id": 75111,
          "ref_liveline_match_id": null,
          "title": "Warwickshire vs Hampshire",
          "short_title": "WAS vs HAM",
          "subtitle": null,
          "format": "5",
          "format_str": "First Class",
          "status": "1",
          "status_str": "Scheduled",
          "status_note": "Starts at 11:00 local time",
          "verified": "false",
          "pre_squad": "false",
          "odds_available": "false",
          "game_state": 0,
          "game_state_str": "Default",
          "domestic": "1",
          "competition_id": 551,
          "date_start": "2024-06-23T04:30:00.000Z",
          "date_end": "2024-06-28T04:30:00.000Z",
          "date_start_ist": "2024-06-23T10:00:00.000Z",
          "date_end_ist": "2024-06-28T10:00:00.000Z",
          "venue_id": 159,
          "umpires": null,
          "referee": null,
          "equation": null,
          "live": null,
          "result": null,
          "result_type": null,
          "win_margin": null,
          "winning_team_id": null,
          "commentary": null,
          "wagon": null,
          "latest_inning_number": null,
          "presquad_time": null,
          "verify_time": null,
          "toss_text": null,
          "toss_winner_id": null,
          "toss_decision": null,
          "is_scorecard_done": 0,
          "liveline_json": {
              "match_id": 75111,
              "title": "Warwickshire vs Hampshire",
              "short_title": "WAS vs HAM",
              "subtitle": "39th Match",
              "match_number": "39",
              "format": 5,
              "format_str": "First Class",
              "status": 1,
              "status_str": "Scheduled",
              "status_note": "Starts at 11:00 local time",
              "verified": "false",
              "pre_squad": "false",
              "odds_available": "false",
              "game_state": 0,
              "game_state_str": "Default",
              "domestic": "1",
              "competition": {
                  "cid": 128541,
                  "title": "Vitality County Championship Division One",
                  "abbr": "County Championship DIV 1st",
                  "type": "tournament",
                  "category": "domestic",
                  "match_format": "firstclass",
                  "season": "2024",
                  "status": "live",
                  "datestart": "2024-04-05",
                  "dateend": "2024-09-26",
                  "country": "en",
                  "total_matches": "70",
                  "total_rounds": "1",
                  "total_teams": "10"
              },
              "teama": {
                  "team_id": 7002,
                  "name": "Warwickshire",
                  "short_name": "WAS",
                  "logo_url": "https://images.entitysport.com/assets/uploads/2023/07/FC-Warwickshire.png"
              },
              "teamb": {
                  "team_id": 7047,
                  "name": "Hampshire",
                  "short_name": "HAM",
                  "logo_url": "https://images.entitysport.com/assets/uploads/2023/07/hampshire-logo.png"
              },
              "date_start": "2024-06-23 10:00:00",
              "date_end": "2024-06-28 10:00:00",
              "timestamp_start": 1719136800,
              "timestamp_end": 1719568800,
              "date_start_ist": "2024-06-23 15:30:00",
              "date_end_ist": "2024-06-28 15:30:00",
              "venue": {
                  "venue_id": "32",
                  "name": "Edgbaston",
                  "location": "Birmingham",
                  "country": "England",
                  "timezone": ""
              },
              "umpires": "",
              "referee": "",
              "equation": "",
              "live": "",
              "result": "",
              "result_type": "",
              "win_margin": "",
              "winning_team_id": 0,
              "commentary": 0,
              "wagon": 0,
              "latest_inning_number": 0,
              "presquad_time": "",
              "verify_time": "",
              "match_dls_affected": "false",
              "day": "0",
              "session": "0",
              "weather": [],
              "pitch": {
                  "pitch_condition": "",
                  "batting_condition": "Average",
                  "pace_bowling_condition": "Average",
                  "spine_bowling_condition": "Average"
              },
              "toss": {
                  "winner": 0,
                  "decision": 0
              }
          },
          "json": null,
          "created_at": "2024-06-20T14:11:39.000Z",
          "updated_at": "2024-06-20T14:11:39.000Z",
          "teama": {
              "id": 448,
              "sport_id": 1,
              "ref_team_id": 7002,
              "ref_liveline_team_id": null,
              "title": "Warwickshire",
              "thumb_url": "FC-Warwickshire.png",
              "logo_url": "FC-Warwickshire.png",
              "type": null,
              "country": null,
              "alt_name": null,
              "abbr": null,
              "created_at": "2024-06-20T14:08:56.000Z",
              "updated_at": "2024-06-20T14:08:56.000Z"
          },
          "teamb": {
              "id": 450,
              "sport_id": 1,
              "ref_team_id": 7047,
              "ref_liveline_team_id": null,
              "title": "Hampshire",
              "thumb_url": "hampshire-logo.png",
              "logo_url": "hampshire-logo.png",
              "type": null,
              "country": null,
              "alt_name": null,
              "abbr": null,
              "created_at": "2024-06-20T14:09:00.000Z",
              "updated_at": "2024-06-20T14:09:00.000Z"
          },
          "venue": {
              "id": 159,
              "ref_venue_id": 32,
              "name": "Edgbaston",
              "location": "Birmingham",
              "country": "England",
              "timezone": 0,
              "created_at": "2024-06-20T14:08:56.000Z",
              "updated_at": "2024-06-20T14:08:56.000Z"
          },
          "competition": {
              "id": 551,
              "season_id": null,
              "ref_competition_id": 128541,
              "title": "Vitality County Championship Division One",
              "abbr": "County Championship DIV 1st",
              "type": null,
              "category": "domestic",
              "match_format": null,
              "status": "live",
              "season": null,
              "datestart": "2024-04-05",
              "dateend": "2024-09-26",
              "total_matches": "70",
              "total_rounds": "1",
              "total_teams": "10",
              "country": "en",
              "game_format": null,
              "json": null,
              "created_at": "2024-04-30T11:05:13.000Z",
              "updated_at": "2024-04-30T11:05:13.000Z"
          }
      }
  }
}`
  },
  {
    method: 'GET',
    path: '/v1/matches/squads/{matchId}',
    description: 'Get match squad',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "response": {
          "data": {
              "id": 942,
              "ref_match_id": 75111,
              "ref_liveline_match_id": null,
              "title": "Warwickshire vs Hampshire",
              "short_title": "WAS vs HAM",
              "subtitle": null,
              "format": "5",
              "format_str": "First Class",
              "status": "1",
              "status_str": "Scheduled",
              "status_note": "Starts at 11:00 local time",
              "verified": "false",
              "pre_squad": "false",
              "odds_available": "false",
              "game_state": 0,
              "game_state_str": "Default",
              "domestic": "1",
              "competition_id": 551,
              "date_start": "2024-06-23T04:30:00.000Z",
              "date_end": "2024-06-28T04:30:00.000Z",
              "date_start_ist": "2024-06-23T10:00:00.000Z",
              "date_end_ist": "2024-06-28T10:00:00.000Z",
              "venue_id": 159,
              "umpires": null,
              "referee": null,
              "equation": null,
              "live": null,
              "result": null,
              "result_type": null,
              "win_margin": null,
              "winning_team_id": null,
              "commentary": null,
              "wagon": null,
              "latest_inning_number": null,
              "presquad_time": null,
              "verify_time": null,
              "toss_text": null,
              "toss_winner_id": null,
              "toss_decision": null,
              "is_scorecard_done": 0,
              "liveline_json": {
                  "match_id": 75111,
                  "title": "Warwickshire vs Hampshire",
                  "short_title": "WAS vs HAM",
                  "subtitle": "39th Match",
                  "match_number": "39",
                  "format": 5,
                  "format_str": "First Class",
                  "status": 1,
                  "status_str": "Scheduled",
                  "status_note": "Starts at 11:00 local time",
                  "verified": "false",
                  "pre_squad": "false",
                  "odds_available": "false",
                  "game_state": 0,
                  "game_state_str": "Default",
                  "domestic": "1",
                  "competition": {
                      "cid": 128541,
                      "title": "Vitality County Championship Division One",
                      "abbr": "County Championship DIV 1st",
                      "type": "tournament",
                      "category": "domestic",
                      "match_format": "firstclass",
                      "season": "2024",
                      "status": "live",
                      "datestart": "2024-04-05",
                      "dateend": "2024-09-26",
                      "country": "en",
                      "total_matches": "70",
                      "total_rounds": "1",
                      "total_teams": "10"
                  },
                  "teama": {
                      "team_id": 7002,
                      "name": "Warwickshire",
                      "short_name": "WAS",
                      "logo_url": "https://images.entitysport.com/assets/uploads/2023/07/FC-Warwickshire.png"
                  },
                  "teamb": {
                      "team_id": 7047,
                      "name": "Hampshire",
                      "short_name": "HAM",
                      "logo_url": "https://images.entitysport.com/assets/uploads/2023/07/hampshire-logo.png"
                  },
                  "date_start": "2024-06-23 10:00:00",
                  "date_end": "2024-06-28 10:00:00",
                  "timestamp_start": 1719136800,
                  "timestamp_end": 1719568800,
                  "date_start_ist": "2024-06-23 15:30:00",
                  "date_end_ist": "2024-06-28 15:30:00",
                  "venue": {
                      "venue_id": "32",
                      "name": "Edgbaston",
                      "location": "Birmingham",
                      "country": "England",
                      "timezone": ""
                  },
                  "umpires": "",
                  "referee": "",
                  "equation": "",
                  "live": "",
                  "result": "",
                  "result_type": "",
                  "win_margin": "",
                  "winning_team_id": 0,
                  "commentary": 0,
                  "wagon": 0,
                  "latest_inning_number": 0,
                  "presquad_time": "",
                  "verify_time": "",
                  "match_dls_affected": "false",
                  "day": "0",
                  "session": "0",
                  "weather": [],
                  "pitch": {
                      "pitch_condition": "",
                      "batting_condition": "Average",
                      "pace_bowling_condition": "Average",
                      "spine_bowling_condition": "Average"
                  },
                  "toss": {
                      "winner": 0,
                      "decision": 0
                  }
              },
              "json": null,
              "created_at": "2024-06-20T14:11:39.000Z",
              "updated_at": "2024-06-20T14:11:39.000Z",
              "competition": {
                  "id": 551,
                  "season_id": null,
                  "ref_competition_id": 128541,
                  "title": "Vitality County Championship Division One",
                  "abbr": "County Championship DIV 1st",
                  "type": null,
                  "category": "domestic",
                  "match_format": null,
                  "status": "live",
                  "season": null,
                  "datestart": "2024-04-05",
                  "dateend": "2024-09-26",
                  "total_matches": "70",
                  "total_rounds": "1",
                  "total_teams": "10",
                  "country": "en",
                  "game_format": null,
                  "json": null,
                  "created_at": "2024-04-30T11:05:13.000Z",
                  "updated_at": "2024-04-30T11:05:13.000Z"
              },
              "teama": {
                  "id": 448,
                  "sport_id": 1,
                  "ref_team_id": 7002,
                  "ref_liveline_team_id": null,
                  "title": "Warwickshire",
                  "thumb_url": "FC-Warwickshire.png",
                  "logo_url": "FC-Warwickshire.png",
                  "type": null,
                  "country": null,
                  "alt_name": null,
                  "abbr": null,
                  "created_at": "2024-06-20T14:08:56.000Z",
                  "updated_at": "2024-06-20T14:08:56.000Z"
              },
              "teamb": {
                  "id": 450,
                  "sport_id": 1,
                  "ref_team_id": 7047,
                  "ref_liveline_team_id": null,
                  "title": "Hampshire",
                  "thumb_url": "hampshire-logo.png",
                  "logo_url": "hampshire-logo.png",
                  "type": null,
                  "country": null,
                  "alt_name": null,
                  "abbr": null,
                  "created_at": "2024-06-20T14:09:00.000Z",
                  "updated_at": "2024-06-20T14:09:00.000Z"
              },
              "venue": {
                  "id": 159,
                  "ref_venue_id": 32,
                  "name": "Edgbaston",
                  "location": "Birmingham",
                  "country": "England",
                  "timezone": 0,
                  "created_at": "2024-06-20T14:08:56.000Z",
                  "updated_at": "2024-06-20T14:08:56.000Z"
              }
          },
          "teama": {
              "team_id": 448,
              "squads": [
                  {
                      "id": 11556,
                      "match_id": 942,
                      "team_id": 448,
                      "player_id": 7793,
                      "role": "wk",
                      "substitute": null,
                      "role_str": null,
                      "playing11": "false",
                      "name": null,
                      "created_at": "2024-06-20T14:11:39.000Z",
                      "updated_at": "2024-06-20T14:11:39.000Z",
                      "player": {
                          "id": 7793,
                          "ref_player_id": 48430,
                          "ref_liveline_player_id": null,
                          "title": "Alex Davies",
                          "short_name": "AL Davies",
                          "first_name": "Alex Davies",
                          "last_name": "",
                          "middle_name": "",
                          "birthdate": "1994-08-23",
                          "birthplace": "Darwen, Lancashire",
                          "country": null,
                          "primary_team": null,
                          "thumb_url": "",
                          "logo_url": "",
                          "playing_role": "wk",
                          "batting_style": null,
                          "bowling_style": null,
                          "fielding_position": null,
                          "recent_match": null,
                          "recent_appearance": null,
                          "fantasy_player_rating": null,
                          "t": null,
                          "nationality": null,
                          "json": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      },
                      "team": {
                          "id": 448,
                          "sport_id": 1,
                          "ref_team_id": 7002,
                          "ref_liveline_team_id": null,
                          "title": "Warwickshire",
                          "thumb_url": "FC-Warwickshire.png",
                          "logo_url": "FC-Warwickshire.png",
                          "type": null,
                          "country": null,
                          "alt_name": null,
                          "abbr": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      }
                  },
                  {
                      "id": 11557,
                      "match_id": 942,
                      "team_id": 448,
                      "player_id": 7795,
                      "role": "wk",
                      "substitute": null,
                      "role_str": null,
                      "playing11": "false",
                      "name": null,
                      "created_at": "2024-06-20T14:11:39.000Z",
                      "updated_at": "2024-06-20T14:11:39.000Z",
                      "player": {
                          "id": 7795,
                          "ref_player_id": 48739,
                          "ref_liveline_player_id": null,
                          "title": "Michael Burgess",
                          "short_name": "MGK Burgess",
                          "first_name": "Michael Burgess",
                          "last_name": "",
                          "middle_name": "",
                          "birthdate": "1994-07-08",
                          "birthplace": "Epsom, Surrey, England",
                          "country": null,
                          "primary_team": null,
                          "thumb_url": "",
                          "logo_url": "",
                          "playing_role": "wk",
                          "batting_style": null,
                          "bowling_style": null,
                          "fielding_position": null,
                          "recent_match": null,
                          "recent_appearance": null,
                          "fantasy_player_rating": null,
                          "t": null,
                          "nationality": null,
                          "json": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      },
                      "team": {
                          "id": 448,
                          "sport_id": 1,
                          "ref_team_id": 7002,
                          "ref_liveline_team_id": null,
                          "title": "Warwickshire",
                          "thumb_url": "FC-Warwickshire.png",
                          "logo_url": "FC-Warwickshire.png",
                          "type": null,
                          "country": null,
                          "alt_name": null,
                          "abbr": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      }
                  },
                  {
                      "id": 11558,
                      "match_id": 942,
                      "team_id": 448,
                      "player_id": 7794,
                      "role": "wk",
                      "substitute": null,
                      "role_str": null,
                      "playing11": "false",
                      "name": null,
                      "created_at": "2024-06-20T14:11:39.000Z",
                      "updated_at": "2024-06-20T14:11:39.000Z",
                      "player": {
                          "id": 7794,
                          "ref_player_id": 108444,
                          "ref_liveline_player_id": null,
                          "title": "Chris Benjamin",
                          "short_name": "C Benjamin",
                          "first_name": "Chris Benjamin",
                          "last_name": "",
                          "middle_name": "",
                          "birthdate": "1999-04-29",
                          "birthplace": "Johannesburg, Gauteng",
                          "country": null,
                          "primary_team": null,
                          "thumb_url": "",
                          "logo_url": "",
                          "playing_role": "wk",
                          "batting_style": null,
                          "bowling_style": null,
                          "fielding_position": null,
                          "recent_match": null,
                          "recent_appearance": null,
                          "fantasy_player_rating": null,
                          "t": null,
                          "nationality": null,
                          "json": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      },
                      "team": {
                          "id": 448,
                          "sport_id": 1,
                          "ref_team_id": 7002,
                          "ref_liveline_team_id": null,
                          "title": "Warwickshire",
                          "thumb_url": "FC-Warwickshire.png",
                          "logo_url": "FC-Warwickshire.png",
                          "type": null,
                          "country": null,
                          "alt_name": null,
                          "abbr": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      }
                  },
                  {
                      "id": 11559,
                      "match_id": 942,
                      "team_id": 448,
                      "player_id": 7800,
                      "role": "bat",
                      "substitute": null,
                      "role_str": null,
                      "playing11": "false",
                      "name": null,
                      "created_at": "2024-06-20T14:11:39.000Z",
                      "updated_at": "2024-06-20T14:11:39.000Z",
                      "player": {
                          "id": 7800,
                          "ref_player_id": 49025,
                          "ref_liveline_player_id": null,
                          "title": "Sam Hain",
                          "short_name": "SR Hain",
                          "first_name": "Sam Hain",
                          "last_name": "",
                          "middle_name": "",
                          "birthdate": "1995-07-16",
                          "birthplace": "Hong Kong",
                          "country": null,
                          "primary_team": null,
                          "thumb_url": "",
                          "logo_url": "",
                          "playing_role": "bat",
                          "batting_style": null,
                          "bowling_style": null,
                          "fielding_position": null,
                          "recent_match": null,
                          "recent_appearance": null,
                          "fantasy_player_rating": null,
                          "t": null,
                          "nationality": null,
                          "json": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      },
                      "team": {
                          "id": 448,
                          "sport_id": 1,
                          "ref_team_id": 7002,
                          "ref_liveline_team_id": null,
                          "title": "Warwickshire",
                          "thumb_url": "FC-Warwickshire.png",
                          "logo_url": "FC-Warwickshire.png",
                          "type": null,
                          "country": null,
                          "alt_name": null,
                          "abbr": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      }
                  },
                  {
                      "id": 11560,
                      "match_id": 942,
                      "team_id": 448,
                      "player_id": 7801,
                      "role": "bat",
                      "substitute": null,
                      "role_str": null,
                      "playing11": "false",
                      "name": null,
                      "created_at": "2024-06-20T14:11:39.000Z",
                      "updated_at": "2024-06-20T14:11:39.000Z",
                      "player": {
                          "id": 7801,
                          "ref_player_id": 95003,
                          "ref_liveline_player_id": null,
                          "title": "Robert Yates",
                          "short_name": "RM Yates",
                          "first_name": "Robert Yates",
                          "last_name": "",
                          "middle_name": "",
                          "birthdate": "1999-09-19",
                          "birthplace": "Solihull, Warwickshire",
                          "country": null,
                          "primary_team": null,
                          "thumb_url": "",
                          "logo_url": "",
                          "playing_role": "bat",
                          "batting_style": null,
                          "bowling_style": null,
                          "fielding_position": null,
                          "recent_match": null,
                          "recent_appearance": null,
                          "fantasy_player_rating": null,
                          "t": null,
                          "nationality": null,
                          "json": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      },
                      "team": {
                          "id": 448,
                          "sport_id": 1,
                          "ref_team_id": 7002,
                          "ref_liveline_team_id": null,
                          "title": "Warwickshire",
                          "thumb_url": "FC-Warwickshire.png",
                          "logo_url": "FC-Warwickshire.png",
                          "type": null,
                          "country": null,
                          "alt_name": null,
                          "abbr": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      }
                  },
                  {
                      "id": 11561,
                      "match_id": 942,
                      "team_id": 448,
                      "player_id": 7798,
                      "role": "bat",
                      "substitute": null,
                      "role_str": null,
                      "playing11": "false",
                      "name": null,
                      "created_at": "2024-06-20T14:11:39.000Z",
                      "updated_at": "2024-06-20T14:11:39.000Z",
                      "player": {
                          "id": 7798,
                          "ref_player_id": 109583,
                          "ref_liveline_player_id": null,
                          "title": "Hamza Shaikh",
                          "short_name": "H Shaikh",
                          "first_name": "Hamza Shaikh",
                          "last_name": "",
                          "middle_name": "",
                          "birthdate": "2006-05-29",
                          "birthplace": "",
                          "country": null,
                          "primary_team": null,
                          "thumb_url": "",
                          "logo_url": "",
                          "playing_role": "bat",
                          "batting_style": null,
                          "bowling_style": null,
                          "fielding_position": null,
                          "recent_match": null,
                          "recent_appearance": null,
                          "fantasy_player_rating": null,
                          "t": null,
                          "nationality": null,
                          "json": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      },
                      "team": {
                          "id": 448,
                          "sport_id": 1,
                          "ref_team_id": 7002,
                          "ref_liveline_team_id": null,
                          "title": "Warwickshire",
                          "thumb_url": "FC-Warwickshire.png",
                          "logo_url": "FC-Warwickshire.png",
                          "type": null,
                          "country": null,
                          "alt_name": null,
                          "abbr": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      }
                  },
                  {
                      "id": 11562,
                      "match_id": 942,
                      "team_id": 448,
                      "player_id": 8818,
                      "role": "bat",
                      "substitute": null,
                      "role_str": null,
                      "playing11": "false",
                      "name": null,
                      "created_at": "2024-06-20T14:11:39.000Z",
                      "updated_at": "2024-06-20T14:11:39.000Z",
                      "player": {
                          "id": 8818,
                          "ref_player_id": 118786,
                          "ref_liveline_player_id": null,
                          "title": "Amir Khan-I",
                          "short_name": "A Khan-I",
                          "first_name": "Amir Khan-I",
                          "last_name": "",
                          "middle_name": "",
                          "birthdate": "",
                          "birthplace": "",
                          "country": null,
                          "primary_team": null,
                          "thumb_url": "",
                          "logo_url": "",
                          "playing_role": "bat",
                          "batting_style": null,
                          "bowling_style": null,
                          "fielding_position": null,
                          "recent_match": null,
                          "recent_appearance": null,
                          "fantasy_player_rating": null,
                          "t": null,
                          "nationality": null,
                          "json": null,
                          "created_at": "2024-06-20T14:11:39.000Z",
                          "updated_at": "2024-06-20T14:11:39.000Z"
                      },
                      "team": {
                          "id": 448,
                          "sport_id": 1,
                          "ref_team_id": 7002,
                          "ref_liveline_team_id": null,
                          "title": "Warwickshire",
                          "thumb_url": "FC-Warwickshire.png",
                          "logo_url": "FC-Warwickshire.png",
                          "type": null,
                          "country": null,
                          "alt_name": null,
                          "abbr": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      }
                  },
                  {
                      "id": 11563,
                      "match_id": 942,
                      "team_id": 448,
                      "player_id": 4074,
                      "role": "bat",
                      "substitute": null,
                      "role_str": null,
                      "playing11": "false",
                      "name": null,
                      "created_at": "2024-06-20T14:11:39.000Z",
                      "updated_at": "2024-06-20T14:11:39.000Z",
                      "player": {
                          "id": 4074,
                          "ref_player_id": 49048,
                          "ref_liveline_player_id": 2854,
                          "title": "Kraigg Brathwaite",
                          "short_name": "KC Brathwaite",
                          "first_name": "Kraigg Brathwaite",
                          "last_name": "",
                          "middle_name": "",
                          "birthdate": "1992-12-01",
                          "birthplace": "Michael, Barbados",
                          "country": null,
                          "primary_team": null,
                          "thumb_url": "",
                          "logo_url": "",
                          "playing_role": "bat",
                          "batting_style": null,
                          "bowling_style": null,
                          "fielding_position": null,
                          "recent_match": null,
                          "recent_appearance": null,
                          "fantasy_player_rating": null,
                          "t": null,
                          "nationality": null,
                          "json": null,
                          "created_at": "2022-11-30T03:18:11.000Z",
                          "updated_at": "2024-06-20T14:11:39.000Z"
                      },
                      "team": {
                          "id": 448,
                          "sport_id": 1,
                          "ref_team_id": 7002,
                          "ref_liveline_team_id": null,
                          "title": "Warwickshire",
                          "thumb_url": "FC-Warwickshire.png",
                          "logo_url": "FC-Warwickshire.png",
                          "type": null,
                          "country": null,
                          "alt_name": null,
                          "abbr": null,
                          "created_at": "2024-06-20T14:08:56.000Z",
                          "updated_at": "2024-06-20T14:08:56.000Z"
                      }
                  },...]`
  },
  {
    method: 'GET',
    path: '/v1/matches/live/{matchId}',
    description: 'Get match live data',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "liveMatch": {
          "id": 702,
          "match_id": 574,
          "status": "3",
          "status_str": "Live",
          "game_state": "3",
          "game_state_str": "Play Ongoing",
          "status_note": "Abu Dhabi elected to bat",
          "team_batting": "Abu Dhabi",
          "team_bowling": "Emirates Blues",
          "live_inning_number": null,
          "live_score_runs": "360",
          "live_score_overs": "38.5",
          "live_score_wickets": "3",
          "live_score_target": "0",
          "live_score_runrate": "9.27",
          "live_score_required_runrate": "",
          "created_at": "2024-04-30T14:45:05.000Z",
          "updated_at": "2024-04-30T14:45:05.000Z",
          "commentaries": [],
          "batsman": [
              {
                  "name": "Alishan Sharafu",
                  "batsman_id": 94179,
                  "runs": 229,
                  "balls_faced": 125,
                  "fours": 12,
                  "sixes": 23,
                  "strike_rate": "183.20"
              },
              {
                  "name": "Mohammad Kamran Atta",
                  "batsman_id": 117186,
                  "runs": 2,
                  "balls_faced": 3,
                  "fours": 0,
                  "sixes": 0,
                  "strike_rate": "66.67"
              },
              {
                  "name": "Alishan Sharafu",
                  "batsman_id": 769,
                  "runs": 229,
                  "balls_faced": 125,
                  "fours": 12,
                  "sixes": 23,
                  "strike_rate": "183.20"
              },
              {
                  "name": "Mohammad Kamran Atta",
                  "batsman_id": 4640,
                  "runs": 2,
                  "balls_faced": 3,
                  "fours": 0,
                  "sixes": 0,
                  "strike_rate": "66.67"
              }
          ],
          "bowlers": [
              {
                  "name": "Abdullah Ismail",
                  "bowler_id": 113697,
                  "overs": 8.5,
                  "runs_conceded": 47,
                  "wickets": 1,
                  "maidens": 0,
                  "econ": "5.32"
              },
              {
                  "name": "Deepak Raj",
                  "bowler_id": 112797,
                  "overs": 2,
                  "runs_conceded": 22,
                  "wickets": 2,
                  "maidens": 0,
                  "econ": "11.00"
              },
              {
                  "name": "Abdullah Ismail",
                  "bowler_id": 4982,
                  "overs": 8.5,
                  "runs_conceded": 47,
                  "wickets": 1,
                  "maidens": 0,
                  "econ": "5.32"
              },
              {
                  "name": "Deepak Raj",
                  "bowler_id": 4973,
                  "overs": 2,
                  "runs_conceded": 22,
                  "wickets": 2,
                  "maidens": 0,
                  "econ": "11.00"
              }
          ]
      }
  }
}`
  },
  {
    method: 'GET',
    path: '/v1/matches/fantasypoint/{matchId}',
    description: 'Get match live data',
    responseFormat: {
      id: 'number',
      name: 'string',
      sport: 'string',
      season: 'string'
    },
    exampleResponse: `{
  "status": true,
  "response": {
      "liveMatch": {
          "id": 702,
          "match_id": 574,
          "status": "3",
          "status_str": "Live",
          "game_state": "3",
          "game_state_str": "Play Ongoing",
          "status_note": "Abu Dhabi elected to bat",
          "team_batting": "Abu Dhabi",
          "team_bowling": "Emirates Blues",
          "live_inning_number": null,
          "live_score_runs": "360",
          "live_score_overs": "38.5",
          "live_score_wickets": "3",
          "live_score_target": "0",
          "live_score_runrate": "9.27",
          "live_score_required_runrate": "",
          "created_at": "2024-04-30T14:45:05.000Z",
          "updated_at": "2024-04-30T14:45:05.000Z",
          "commentaries": [],
          "batsman": [
              {
                  "name": "Alishan Sharafu",
                  "batsman_id": 94179,
                  "runs": 229,
                  "balls_faced": 125,
                  "fours": 12,
                  "sixes": 23,
                  "strike_rate": "183.20"
              },
              {
                  "name": "Mohammad Kamran Atta",
                  "batsman_id": 117186,
                  "runs": 2,
                  "balls_faced": 3,
                  "fours": 0,
                  "sixes": 0,
                  "strike_rate": "66.67"
              },
              {
                  "name": "Alishan Sharafu",
                  "batsman_id": 769,
                  "runs": 229,
                  "balls_faced": 125,
                  "fours": 12,
                  "sixes": 23,
                  "strike_rate": "183.20"
              },
              {
                  "name": "Mohammad Kamran Atta",
                  "batsman_id": 4640,
                  "runs": 2,
                  "balls_faced": 3,
                  "fours": 0,
                  "sixes": 0,
                  "strike_rate": "66.67"
              }
          ],
          "bowlers": [
              {
                  "name": "Abdullah Ismail",
                  "bowler_id": 113697,
                  "overs": 8.5,
                  "runs_conceded": 47,
                  "wickets": 1,
                  "maidens": 0,
                  "econ": "5.32"
              },
              {
                  "name": "Deepak Raj",
                  "bowler_id": 112797,
                  "overs": 2,
                  "runs_conceded": 22,
                  "wickets": 2,
                  "maidens": 0,
                  "econ": "11.00"
              },
              {
                  "name": "Abdullah Ismail",
                  "bowler_id": 4982,
                  "overs": 8.5,
                  "runs_conceded": 47,
                  "wickets": 1,
                  "maidens": 0,
                  "econ": "5.32"
              },
              {
                  "name": "Deepak Raj",
                  "bowler_id": 4973,
                  "overs": 2,
                  "runs_conceded": 22,
                  "wickets": 2,
                  "maidens": 0,
                  "econ": "11.00"
              }
          ]
      }
  }
}`
  },
  // Add more endpoints here...
];